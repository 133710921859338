import { CONTENTS_OBJECT } from '@/common/model/constants';
import { useEffect } from 'react';

const loadingImg = Public.GetLoadingImage('svg');

const SWIPER_ROOT_ID = 'div_main_banner_issue';

const LIST = PageVariable[CONTENTS_OBJECT.SlideBannerList.DATA_NAME] || [];

const SlideBannerList = () => {
  useEffect(() => {
    if (LIST?.length && LIST?.length > 1) {
      new Swiper6(`#${SWIPER_ROOT_ID}`, {
        loop: true,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },

        lazy: true,
        // watchSlidesVisibility: true,
        // watchSlidesProgress: true,
      });
    }
  }, []);

  if (LIST.length === 0) {
    return null;
  }

  return (
    <div className="main-issuebanner">
      <div className="swp_tmp" id={SWIPER_ROOT_ID}>
        <ul className="swp_cont">
          {LIST.map((item, index) => {
            const lazyLoadingProps =
              LIST?.length > 1 ? { 'data-src': item.IMG_URL } : { gd_src: item.IMG_URL, load: 'N' };
            return (
              <li className="swp_slide" key={index}>
                <a href={item.LINK_URL}>
                  <img
                    src={loadingImg}
                    {...lazyLoadingProps}
                    width="750"
                    height="125"
                    alt={item.TITLE}
                    className="swp_lazy"
                  />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default SlideBannerList;
