import { AdToolTip } from '../../../../common/components/AdToolTip';
import { adButtonOnClickFunc } from '../../../../common/util/function';

const loadingImg = Public.GetLoadingImage('svg');

const BrandPremium = ({ data }) => {
  if (data?.length <= 0) return;

  return data.map((item, index) => {
    const { TYPE, BRAND_TITLE, REMAIN_BRANDMON_AMOUNT, ETC_INFO } = item;

    const etcInfoObj = Qoo10FE.Util.Object.jsonParse(ETC_INFO);
    let image_class = 'tweet-feed__thumb';

    if (TYPE === 'rank') {
      if (etcInfoObj && etcInfoObj['gd_img'] === 'Y') {
        image_class = 'tweet-feed__thumb tweet-feed__thumb--type-square';
      } else if (REMAIN_BRANDMON_AMOUNT > 0) {
        image_class = 'tweet-feed__thumb tweet-feed__thumb--size-big';
      }
    } else if (REMAIN_BRANDMON_AMOUNT > 0) {
      // brandmon_amount 0보다 크면 마메고 이벤트 진행 중으로 Big Image Type 으로 노출시킨다.
      image_class = 'tweet-feed__thumb tweet-feed__thumb--size-big';
    }

    return (
      <li
        data-type="brand"
        data-brand_type={TYPE}
        key={index}
        onClick={(event) => {
          adButtonOnClickFunc(event, 'style="right:auto; left:0;"');
        }}
      >
        <a href={item.LINK_URL} className="tweet-feed">
          <div className={image_class} gd_src={item.IMG_URL} load="N" style={{ backgroundImage: loadingImg }}></div>
          <div className="tweet-feed__cont">
            <div className="tweet-feed__desc">{item.TITLE}</div>
            <div className="tweet-feed__detail">
              <div className="sender">
                <span className="sender__info">
                  <span className="title">
                    <span className="name">
                      <span className="flag-brand flag-brand--level">
                        {item.OFFICIAL_BRAND ? <span className="ofc">Official Brand</span> : null}

                        {REMAIN_BRANDMON_AMOUNT > 0 ? (
                          BRAND_TITLE
                        ) : (
                          <span className="flag-brand__level">{BRAND_TITLE}</span>
                        )}
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              {/* // 마메고 이벤트 브랜드몬 개수 노출 */}
              {REMAIN_BRANDMON_AMOUNT > 0 ? (
                <div className="event">
                  <img src={brandmon_img_url} alt="brandmon" width="16" />
                  <em>{PriceUtil.FormatNumber(REMAIN_BRANDMON_AMOUNT)}</em>
                  <span className="event__txt">
                    {String.format(MultiLang.findCommonResource('Main/us/index.aspx', 'Remains'), item.BRANDMON_TITLE)}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </a>
        {TYPE === 'premium' ? <AdToolTip rootClass={'main-tweet__ad main-tweet__ad--left'} /> : null}
      </li>
    );
  });
};
export default BrandPremium;
