import { useEffect } from 'react';
import ItemCardArea from './ItemCardArea';
import { useState } from 'react';

const Contents = ({ wePickItems, wepickData, wid }) => {
  const { TITLE, DISP_TYPE, LINK_URL, CONTENTS_TYPE, BACKGROUND_IMG_URL } = wepickData;
  const { ETC_INFO, ITEMS, BANNER } = wePickItems;

  // 데이터 검증
  if ((CONTENTS_TYPE !== 'BA' && wePickItems?.ITEMS?.length <= 0) || (CONTENTS_TYPE === 'BA' && !BANNER?.HTML)) {
    $(`#io_${wid}`).remove();
    return;
  }
  const etcInfoObj = Qoo10FE.Util.Object.jsonParse(ETC_INFO);

  const Timer = () => {
    const ts_end_time = etcInfoObj && etcInfoObj['ts_end_time'] ? etcInfoObj['ts_end_time'] : '';
    const ts_open_time = etcInfoObj && etcInfoObj['ts_open_time'] ? etcInfoObj['ts_open_time'] : '';
    useEffect(() => {
      if (ts_end_time)
        Countdown.Create(
          'TimeSaleTimer',
          `ts_count_down_${wid}`,
          ts_end_time.replace('  ', ' '), //ios에서 중간 공백 두칸일때 new Date 실행 오류나는 부분 커버
          'first_load',
          'inner_html',
        );
    }, [ts_end_time]);

    return ts_end_time ? (
      <span className="time-sale" id={`ts_count_down_${wid}`}>
        <span className="time-sale__count" data-type="count"></span>
      </span>
    ) : ts_open_time ? (
      <span className="time-sale">
        <span className="time-sale__info">{ts_open_time}</span>
      </span>
    ) : null;
  };

  const PBToolTip = () => {
    const [isPBTootipLayerView, setIsPBTootipLayerView] = useState(false);
    return (
      <div className="c-tooltip">
        <button
          type="button"
          className="info-button"
          onClick={() => {
            setIsPBTootipLayerView(!isPBTootipLayerView);
          }}
        >
          {MultiLang.findCommonResource('Main/us/index.aspx', 'Sponsored')}
        </button>

        <div
          className="c-tooltip__cont"
          style={{ display: !isPBTootipLayerView ? 'none' : null, top: 'calc(100% + 5px)' }}
        >
          <button
            type="button"
            className="c-tooltip__close"
            onClick={() => {
              setIsPBTootipLayerView(!isPBTootipLayerView);
            }}
          >
            <span className="hide">닫기</span>
          </button>
          <div>
            {' '}
            {MultiLang.findCommonResource(
              'Main/us/index.aspx',
              '광고를 구매한 상품으로 판매자의 광고 구매금액과 광고 반응률을 고려하여 노출됩니다.',
            )}
          </div>
        </div>
      </div>
    );
  };

  const Header = () => {
    const Base = () => {
      return (
        <div className={`main-title${LINK_URL && ' main-title--link'}`}>
          <h2 className="main-title__name">{TITLE}</h2>
          {CONTENTS_TYPE === 'TD' ? <Timer /> : null}
          {CONTENTS_TYPE === 'PB' ? <PBToolTip /> : null}
          {LINK_URL && (
            <a className="main-title__link" href={LINK_URL}>
              <span className="hide">{MultiLang.findCommonResource('Main/us/index.aspx', 'See all')}</span>
            </a>
          )}
        </div>
      );
    };

    return DISP_TYPE === 'S' && BACKGROUND_IMG_URL ? (
      <div className="main-cardsetting__head" style={{ backgroundImage: `url(${BACKGROUND_IMG_URL})` }}>
        <Base />
      </div>
    ) : (
      <Base />
    );
  };

  const BannerTypeHtml = () => {
    return <div style={{ marginTop: '20px' }} dangerouslySetInnerHTML={{ __html: BANNER.HTML }}></div>;
  };

  return (
    <>
      {CONTENTS_TYPE === 'BA' ? (
        <BannerTypeHtml />
      ) : (
        <>
          <Header />
          <ItemCardArea LIST={ITEMS} wepickData={wepickData} wid={wid} />
        </>
      )}
    </>
  );
};
export default Contents;
