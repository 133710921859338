import { useEffect } from 'react';
import { AdToolTip } from '../../../../../common/components/AdToolTip';

const loadingImg = Public.GetLoadingImage('svg');
const loadingShopImg = Public.GetLoadingImage('shop');
const Premium = ({ item }) => {
  useEffect(() => {
    UIVideoUtil.init();
    UIVideoUtil.scrollAutoPlay();
    // Following 셀러샵
    UICommonFollowingShop.init();
  }, []);

  const isVideo = item.VC_NO > 0 && item.VIDEO_THUMB_IMG ? true : false;
  const video_type = isVideo && item.VIDEO_TYPE === 'YT' ? 'YouTube' : isVideo ? 'Qoo10' : '';
  const etcInfoObj = Qoo10FE.Util.Object.jsonParse(item.ETC_INFO);
  const send_dt = etcInfoObj && etcInfoObj['send_dt'] ? etcInfoObj['send_dt'] : item.ETC_INFO;

  return (
    <>
      <a href={item.LINK_URL} className="tweet-feed">
        {isVideo ? (
          <div
            className={
              item.REMAIN_BRANDMON_AMOUNT > 0 ? 'tweet-feed__thumb tweet-feed__thumb--size-big ' : 'tweet-feed__thumb'
            }
          >
            <div className="temp-livevideo" data-auto_play_flag={video_type !== 'YouTube' ? 'S' : ''}>
              <div
                id={`tweet_video_${item.VC_NO}`}
                data-common_video="Y"
                data-vc_no={item.VC_NO}
                data-thumb_img={item.VIDEO_THUMB_IMG}
                data-play_url={item.VIDEO_PLAY_URL}
                data-video_load="N"
                data-video_type={item.VIDEO_TYPE}
                data-auto_play_flag={video_type !== 'YouTube' ? 'S' : ''}
              ></div>
            </div>
          </div>
        ) : (
          <div
            className={
              item.REMAIN_BRANDMON_AMOUNT > 0 ? 'tweet-feed__thumb tweet-feed__thumb--size-big' : 'tweet-feed__thumb'
            }
            gd_src={item.VC_NO > 0 ? item.VIDEO_THUMB_IMG : item.IMG_URL}
            load="N"
            style={{ backgroundImage: loadingImg }}
          ></div>
        )}

        <div className="tweet-feed__cont">
          <div className="tweet-feed__desc">{item.TITLE}</div>
          <div className="tweet-feed__detail">
            <div className="sender">
              <span
                className="sender__img"
                gd_src={item.SHOP_IMAGE}
                load="N"
                style={{ backgroundImage: loadingShopImg }}
              ></span>
              <span className="sender__info">
                <span className="title">
                  <span className="name">{item.SHOP_TITLE}</span>
                  <span
                    className="tag-fllwg"
                    title="following"
                    style={{ display: 'none' }}
                    data-sell_cust_no={item.ENC_SELL_CUST_NO}
                    data-chk_follow="N"
                  >
                    <span className="hide">Following</span>
                  </span>
                </span>
                {item.REMAIN_BRANDMON_AMOUNT > 0 && send_dt ? <span className="time">{send_dt}</span> : ''}
              </span>
            </div>
            {item.REMAIN_BRANDMON_AMOUNT > 0 ? (
              <div className="event">
                <img src={item.BRANDMON_IMG_URL} alt="brandmon" width="16" />
                <em>{PriceUtil.FormatNumber(item.REMAIN_BRANDMON_AMOUNT)}</em>
                <span className="event__txt">
                  {String.format(MultiLang.findCommonResource('Main/us/index.aspx', 'Remains'), item.BRANDMON_TITLE)}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </a>
      {item.TYPE === 'tweet' ? <AdToolTip rootClass={'main-tweet__ad main-tweet__ad--left'} /> : null}
    </>
  );
};
export default Premium;
