import { CONTENTS_OBJECT } from '@/common/model/constants';
import { useState, useRef, useEffect } from 'react';
import { adButtonOnClickFunc } from '../../common/util/function';
import { AdToolTip } from '../../common/components/AdToolTip';

const loadingImg = Public.GetLoadingImage('svg');

const SWIPER_ROOT_ID = 'div_main_banner_outer';
const ALLVIEWER_ID = 'ly_main_banner_lstall';
const SWIPER_ALLVIEWER_ID = 'div_main_bnr_lst_all_swiper';

const LIST = PageVariable[CONTENTS_OBJECT.MainBannerList.DATA_NAME] || [];

const MainBannerList = () => {
  const [isAllViewerOpen, setIsAllViewerOpen] = useState(false);
  const [isAllViewerOpened, setIsAllViewerOpened] = useState(false);

  useEffect(() => {
    if (LIST.length > 1) {
      new Swiper6(`#${SWIPER_ROOT_ID}`, {
        initialSlide: Math.floor(Math.random() * LIST.length),
        pagination: {
          el: 'a.main-bigbanner__paging div.swp_pg',
          type: 'fraction',
          clickable: true,
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false, // 쓸어 넘기거나 버튼 클릭 시 자동 슬라이드 정지.
        },
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 1,
          loadOnTransitionStart: true,
        },
        // lazy: true,
        loop: true,
        // watchSlidesVisibility: true,
        // watchSlidesProgress: true,
        on: {
          slideChange: () => {
            $('ul.swp_cont div[data-id=ad_layer]').remove();
          },
        },
      });
    } else {
      new Swiper6(`#${SWIPER_ROOT_ID}`, {
        initialSlide: 1,
        lazy: true,
      });
    }
  }, []);

  const scroll_posY = useRef(0);

  useEffect(() => {
    const allViewer = $(`#${ALLVIEWER_ID}`);
    if (!isAllViewerOpen) {
      MobileUtil.OpenLayerScrollFlex(scroll_posY.current, '');
      allViewer.fadeOut(250).find('.ly_mr_sg').removeClass('is_opn');
      allViewer.find('.ly_mr_bg').hide();
      return;
    }
    // 레이어 열림(+ 버튼으로 펼칠때)
    if (isAllViewerOpen) {
      // 배너가 5개 이상일 경우, 레이어 전체목록 창을 생성하기에 해당 창이 존재확인 후 이벤트진행
      if (allViewer.length <= 0) {
        return;
      }

      allViewer.show();

      if (!isAllViewerOpened) {
        setIsAllViewerOpened(true);
      }

      if (!isAllViewerOpened) {
        // 숨겨진 전체보기 layer 이미지 로딩
        $(`#${SWIPER_ALLVIEWER_ID} li`).each(function () {
          DelayImageLoading.ImageResize($(this).find('a img'));
        });

        // 스와이퍼 생성
        if ($(`#${SWIPER_ALLVIEWER_ID} li`).length > 0) {
          new Swiper6(`#${SWIPER_ALLVIEWER_ID}`, {
            scrollbar: {
              el: '.swp_scrollbar',
              hide: true,
              draggable: true,
            },
            mousewheel: true,
            slidesPerView: 'auto',
            direction: 'vertical',
            freeMode: true,
            observer: true,
            observeParent: true,
          });
        }
      }

      // toggle 처리 & addClass 처리
      scroll_posY.current = $(window).scrollTop();
      MobileUtil.OpenLayerScrollFix(scroll_posY.current, (e) => {
        e.preventDefault();
      });
      allViewer.fadeIn(100).find('.ly_mr_sg').addClass('is_opn');
      allViewer.find('.ly_mr_bg').show();
    }
  }, [isAllViewerOpen, isAllViewerOpened]);

  if (LIST.length === 0) {
    return null;
  }

  LIST.forEach((item) => {
    const etcInfoObj = Qoo10FE.Util.Object.jsonParse(item.ETC_INFO);
    item.is_ad = etcInfoObj && etcInfoObj['ad'] && etcInfoObj['ad'] === 'Y' ? true : false;
  });

  return (
    <div className="main-bigbanner">
      <div className="swp_tmp" id={SWIPER_ROOT_ID}>
        <ul className="swp_cont" onClick={adButtonOnClickFunc}>
          {LIST?.map((item, index) => {
            const lazyloadindProp =
              LIST?.length > 1 ? { 'data-src': item.IMG_URL } : { gd_src: item.IMG_URL, load: 'N' };
            return (
              <li className="swp_slide" key={index}>
                <a href={item.LINK_URL}>
                  <img
                    src={loadingImg}
                    {...lazyloadindProp}
                    width="800"
                    height="450"
                    alt={item.TITLE}
                    className="swp_lazy"
                  />
                </a>
                {item.is_ad ? <AdToolTip rootClass={'main-bigbanner__ad'} /> : null}
              </li>
            );
          })}
        </ul>

        {LIST?.length > 1 ? (
          <a
            role="button"
            className="main-bigbanner__paging"
            onClick={() => {
              setIsAllViewerOpen(true);
            }}
          >
            <div className="swp_pg"></div>
            <i></i>
          </a>
        ) : null}
      </div>

      <section className="ly_mr" id={ALLVIEWER_ID}>
        <div className="ly_mr_sg ">
          <h1 className="ly_mr_hd">
            전체 목록 보기
            <button
              type="button"
              className="bt_cls"
              aria-label="close layer"
              onClick={() => {
                setIsAllViewerOpen(false);
              }}
            >
              close
            </button>
          </h1>
          <div className="all-promotion swp_tmp" id={SWIPER_ALLVIEWER_ID}>
            <div className="swp_cont">
              <ul className="swp_slide" onClick={adButtonOnClickFunc}>
                {LIST?.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.LINK_URL}>
                        <img src={loadingImg} gd_src={item.IMG_URL} load="N" alt={item.TITLE} />
                      </a>
                      {item.is_ad ? <AdToolTip rootClass={'main-bigbanner__ad'} /> : null}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="swp_scrollbar"></div>
          </div>
        </div>
        <div
          className="ly_mr_bg"
          data-type="div_main_bnr_layer_bg"
          style={{ display: 'none' }}
          onClick={() => {
            setIsAllViewerOpen(false);
          }}
        ></div>
      </section>
    </div>
  );
};

export default MainBannerList;
