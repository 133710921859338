import { useEffect } from 'react';
const Type1 = ({
  LIST,
  wepickData,
  wid,
  //isPagingDisplay,
  PBLogFuncBase,
  PItem,
  bestSellerSticker,
  //loadingImg,
  pb_gdsc_cd,
  pb_keyword,
  pb_source,
  CHANNEL_ID,
}) => {
  const { CONTENTS_TYPE } = wepickData;
  useEffect(() => {
    if (CONTENTS_TYPE === 'PB') {
      window.addEventListener('scroll', function () {
        const observeTop = $(window).scrollTop() + $(window).height();
        $(`#io_${wid} li[data-wish_org_gd_no]`).each(function () {
          const target = $(this);
          const targetTop = target.offset().top + target.height() / 2;
          const wish_org_gd_no = target.attr('data-wish_org_gd_no');
          if (wish_org_gd_no && observeTop > targetTop) {
            Util.CallWishProductBoostLogApi('I', CHANNEL_ID, pb_gdsc_cd, pb_keyword, wish_org_gd_no, pb_source);
            target.removeAttr('data-wish_org_gd_no');
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    UIVideoUtil.init();
    UIVideoUtil.scrollAutoPlay();
  }, []);

  return (
    <div className="p-itemcard p-itemcard--grid1">
      <ul data-item_total_cnt={LIST.length} className="p-itemcard__gallery">
        {LIST.map((item, index) => {
          const PBLogFunc = (event) => {
            if (item.wish_org_gd_no) PBLogFuncBase(event, item.wish_org_gd_no, item.LINK_URL);
          };
          const priceNoticeStr =
            CONTENTS_TYPE === 'TD' && GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.WeMakePriceWorld ? (
              <em className="sub sub--event" key={`${wid}_${index}`}>
                {MultiLang.findCommonResource('Main/us/index.aspx', '타임세일가') || '타임세일가'}
              </em>
            ) : null;

          return (
            <PItem
              item={item}
              key={`${wid}_${index}`}
              liClass={'p-item'}
              liAddData={{ 'data-wish_org_gd_no': item.wish_org_gd_no }}
              {...bestSellerSticker(0, index, CONTENTS_TYPE)}
              onclickFunc={CONTENTS_TYPE === 'PB' ? PBLogFunc : null}
              useRateNReviewCnt={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? true : false}
              useRemainCnt={true}
              useRecommendCnt={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? false : true}
              useShippingInfo={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? false : true}
              useReferPrice={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? false : true}
              useGraph={CONTENTS_TYPE === 'GB'}
              useShipFromInfo={false}
              priceNoticeStr={priceNoticeStr}
              linkType={'move'}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default Type1;
