import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import ShoppingTweetContents from './Contents';

const ShoppingTweet = () => {
  const loaderRef = useRef(null);

  useEffect(() => {
    if (!loaderRef.current) return;

    const observer = new IntersectionObserver(
      (entries, observer) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting) {
          $(firstEntry.target).css('height', '');
          observer.unobserve(firstEntry.target);
          createRoot(firstEntry.target).render(<ShoppingTweetContents />);
        }
      },
      { rootMargin: '200px 0px ' },
    );

    observer.observe(loaderRef.current);
  }, [loaderRef]);

  return <div className="main-row" id="tweet_area" ref={loaderRef} style={{ height: '200px' }}></div>;
};
export default ShoppingTweet;
