import { useEffect } from 'react';
import { AdToolTip } from '../../../../common/components/AdToolTip';
import { adButtonOnClickFunc } from '../../../../common/util/function';

const ADLiveBanner = ({ data }) => {
  useEffect(() => {
    UIVideoUtil.init();
    UIVideoUtil.scrollAutoPlay();
  }, []);

  if (data?.ITEMS?.length <= 0) return;

  return data?.ITEMS?.map((item, index) => {
    const ad_live_banner_thumb_img_url = item.VC_NO > 0 ? item.VIDEO_THUMB_URL : item.GD_IMG_URL;
    //const deal_type = !item.deal_type ? 'ADPlus' : item.deal_type;
    //const targetId = `shop_live_video_shop_ad_${deal_type}_${item.VC_NO}`; //BE에서 deal_type값 주지 않아서 gd_no로 변경
    const targetId = `shop_live_video_shop_ad_${item.GD_NO}_${item.VC_NO}`;
    return (
      <li
        key={index}
        data-type="ad_live_banner"
        //data-deal_type={deal_type}
        data-ad_live_banner_type={item.AD_LIVE_BANNER_TYPE}
        onClick={adButtonOnClickFunc}
      >
        <div className="temp-tweet__feed temp-tweet__feed--promotion">
          <div className="temp-tweet__thmb temp-tweet__thmb--size-big">
            <div className="temp-livevideo" data-auto_play_flag={item.VIDEO_TYPE !== 'YouTube' ? 'S' : ''}>
              <div
                id={targetId}
                data-common_video="Y"
                data-vc_no={item.VC_NO}
                data-thumb_img={ad_live_banner_thumb_img_url}
                data-play_url={item.VIDEO_PLAY_URL}
                data-video_load="N"
                data-video_type={item.VIDEO_TYPE}
                data-auto_play_flag={item.VIDEO_TYPE !== 'YouTube' ? 'S' : ''}
              ></div>

              {/* Live Banner Plus 인경우 클릭시 처리 */}
              {item.AD_LIVE_BANNER_TYPE === 'P' ? (
                <a
                  className="temp-livevideo__open"
                  role="button"
                  data-href={Public.getMobileServerUrl(
                    `/Popup/LiveBannerPlusVideoLayer.aspx?g=${item.GD_NO}&vn=${item.VC_NO}`,
                  )}
                  onClick={(event) => {
                    if (window.UIVideoUtil && UIVideoUtil.moveNewVideoLayer)
                      UIVideoUtil.moveNewVideoLayer(event.target);
                  }}
                  data-target_id={targetId}
                >
                  Live Banner Plus Layer Open
                </a>
              ) : null}
            </div>
          </div>
          <a href={item.LINK_URL} className="temp-tweet__cont" title={item.GD_NM}>
            <div className="temp-tweet__txt">{item.GD_NM}</div>
            <div className="temp-tweet__prc">
              {/* 상품 할인율 */}
              {item.DISCOUNT_RATE > 0 ? (
                <span className="dc" title={`discount ${item.DISCOUNT_RATE}%`}>
                  <strong>
                    {item.DISCOUNT_RATE}% {MultiLang.findCommonResource('Main/us/index.aspx', 'OFF')}
                  </strong>
                </span>
              ) : null}
              {item.DISPLAY_REFER_PRICE ? <strong title="selling price">{item.DISPLAY_REFER_PRICE}</strong> : null}
            </div>
          </a>
        </div>
        <AdToolTip rootClass={'main-tweet__ad'} />
      </li>
    );
  });
};
export default ADLiveBanner;
