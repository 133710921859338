import { useEffect } from 'react';

const loadingImg = Public.GetLoadingImage('svg');

const Push = ({ item }) => {
  useEffect(() => {
    // Following 셀러샵
    UICommonFollowingShop.init();
  }, []);

  if (!item) return;

  const etcInfoObj = Qoo10FE.Util.Object.jsonParse(item.ETC_INFO);
  const send_dt = etcInfoObj && etcInfoObj['send_dt'] ? etcInfoObj['send_dt'] : item.ETC_INFO;

  return (
    <a href={item.LINK_URL} className="tweet-feed">
      <div
        className={item.REMAIN_ADMON_AMOUNT > 0 ? 'tweet-feed__thumb tweet-feed__thumb--size-big' : 'tweet-feed__thumb'}
        gd_src={item.IMG_URL}
        load="N"
        style={{ backgroundImage: loadingImg }}
      ></div>
      <div className="tweet-feed__cont">
        <div className="tweet-feed__desc">{item.COMMENT}</div>
        <div className="tweet-feed__detail">
          <div className="sender">
            <span
              className="sender__img"
              gd_src={item.SHOP_IMAGE}
              load="N"
              style={{ backgroundImage: loadingImg }}
            ></span>
            <span className="sender__info">
              <span className="title">
                <span className="name">{item.SHOP_TITLE}</span>
                <span
                  className="tag-fllwg"
                  title="following"
                  style={{ display: 'none' }}
                  data-sell_cust_no={item.ENC_SELL_CUST_NO}
                  data-chk_follow="N"
                >
                  <span className="hide">Following</span>
                </span>
              </span>
              <span className="time">{send_dt}</span>
            </span>
          </div>
          {item.REMAIN_ADMON_AMOUNT > 0 ? (
            <div className="event">
              <img src={item.MAME_IMG_URL} alt="Mamemon" width="16" />
              <em className="mame">{PriceUtil.FormatNumber(item.REMAIN_ADMON_AMOUNT)}</em>
              <span className="event__txt">
                {string.Format(MultiLang.findCommonResource('Main/us/index.aspx', 'Remains'), item.MAME_TYPE)}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </a>
  );
};

export default Push;
