import { useEffect, useRef, useState } from 'react';

const ShoppingTrendKeyword = () => {
  const loaderRef = useRef(null);
  const [LIST, setList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalPageCnt, setTotalPageCnt] = useState(0);
  const PAGE_SIZE = 10;

  const observer = new IntersectionObserver(
    (entries, observer) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        observer.unobserve(firstEntry.target); //다음페이지 확인후 다시 in!
        setPageNo((prev) => prev + 1);
      }
    },
    { rootMargin: '5000px 0px ' },
  );

  const loadMore = async () => {
    const apiUrl = Public.getServiceUrl('Mobile/Main/FEMainAPI.aspx');
    const apiName = 'GetShoppingTrendKeywordItems';
    const url = `${apiUrl}/${apiName}/${pageNo}`;

    const HEADER_FETCH_DATE = 'cache-date';

    const cache = await caches.open(apiName); // 캐시 저장소 열기
    const cacheResponse = await caches.match(url);
    const getResponse = () => cacheResponse.json();
    //const ONE_DAY_MILISECOND = 60000; //60 * 60 * 24 * 1000;
    const FIVE_MINUTE_MILISECOND = 60 * 5 * 1000;

    const isCacheExpired = (cacheResponse) => {
      const fetchDate = new Date(cacheResponse.headers.get(HEADER_FETCH_DATE)).getTime();
      const today = new Date().getTime();

      return today - fetchDate > FIVE_MINUTE_MILISECOND;
    };

    const setData = (result) => {
      if (pageNo === 1) {
        if (result.TOTAL_PAGE_NO === 0) {
          $('#div_main_shopping_trend_keyword').remove();
        }
        setTotalPageCnt(result.TOTAL_PAGE_NO);
      }
      setList((prev) => [...prev, ...result.SHOPPING_KEYWORD_ITEMS]);
    };

    if (cacheResponse) {
      if (location.search.indexOf('__nocache=Y') < 0 && !isCacheExpired(cacheResponse)) {
        getResponse().then((res) => {
          setData(res.d);
        });

        return;
      }
    }

    //api호출
    const param = new RMSParam();
    param.add('page_no', pageNo);
    param.add('page_size', PAGE_SIZE);
    RMSHelper.asyncCallWebMethod(
      apiUrl,
      apiName,
      param.toJson(),
      async function (result, svc, methodName, xmlHttpasync) {
        if (!result) return;

        setData(result);

        //조회된 데이터 cache
        const cloneResponse = new Response(xmlHttpasync.response);
        const newBody = await cloneResponse.blob();
        let newHeaders = new Headers(cloneResponse.headers);
        newHeaders.append(HEADER_FETCH_DATE, new Date().toISOString());
        const newResponse = new Response(newBody, {
          status: cloneResponse.status,
          statusText: cloneResponse.statusText,
          headers: newHeaders,
        });
        cache.put(url, newResponse);
      },
    );
  };

  //pageNo 변경되면 다음페이지 조회
  useEffect(() => {
    if (pageNo <= 0) return;

    loadMore();
  }, [pageNo]);

  //조회된 LIST로 변경된후, 다음페이지 있다면 observer 다시 in!
  useEffect(() => {
    if (totalPageCnt > 0 && pageNo >= totalPageCnt) {
      $(loaderRef.current).remove();
      return;
    }

    if (totalPageCnt > 0 && LIST?.length > 0 && pageNo < totalPageCnt) {
      observer.observe(loaderRef.current);
    }
  }, [LIST.length]);

  useEffect(() => {
    const scrollFunc = () => {
      const observeTop = $(window).scrollTop() + $(window).height();
      $(`[data-type=ul_shopping_trending]`).each(function () {
        const target = $(this);
        const targetLi = target.find('li[data-smart_ad_x]');
        if (targetLi.length > 0) {
          const targetTop = target.offset().top + target.height() / 2;
          if (observeTop > targetTop) {
            const smart_ad_x_list = [];
            targetLi.map(function () {
              const smart_ad_x = $(this).attr('data-smart_ad_x');
              smart_ad_x && smart_ad_x_list.push(smart_ad_x);
              $(this).removeAttr('data-smart_ad_x');
            });

            if (smart_ad_x_list.length > 0) {
              Util.CallSmartAdLogApi('impression', pid, smart_ad_x_list.toString());
            }
          }
        }
      });
    };
    window.addEventListener('scroll', function () {
      scrollFunc();
    });
    scrollFunc();
  }, []);

  const { PItem } = Qoo10FEComponents;

  return (
    <div className="main-row" id="div_main_shopping_trend_keyword">
      <div className="main-title">
        <h2 className="main-title__name">
          {MultiLang.findCommonResource('Main/us/index.aspx', 'Shopping Trend Keyword')}
        </h2>
      </div>
      {LIST?.length > 0 ? (
        <div className="trend-keyword" data-type="div_shopping_trending_keyword_list" data-append_type="main">
          {LIST?.map((t, i) => {
            return (
              <div className="trend-keyword__group" key={i}>
                <div className="trend-keyword__head">
                  <a href={t.LINK_URL} className="keyword">
                    <h3>{t.TITLE}</h3>
                  </a>
                  {t.LINK_TEXT && <p>{t.LINK_TEXT}</p>}
                </div>
                <div className="trend-keyword__list">
                  <div className="scroll">
                    <ul data-type="ul_shopping_trending" data-key={i}>
                      {t.BANNER?.IMG_URL && (
                        <li className="trend-keyword__banner">
                          <a href={t.BANNER.LINK_URL}>
                            <img src={t.BANNER.IMG_URL} alt={t.BANNER.TITLE} />
                          </a>
                        </li>
                      )}
                      {t.ITEMS?.map((item, index) => {
                        const etcInfoObj = Qoo10FE.Util.Object.jsonParse(item.ETC_INFO);
                        const smart_ad_x = etcInfoObj && etcInfoObj['smart_ad_x'] ? etcInfoObj['smart_ad_x'] : '';
                        const SmartADFunc = () => {
                          Util.CallSmartAdLogApi('click', pid, smart_ad_x);
                        };
                        item['is_ad'] = smart_ad_x ? true : false;
                        const liAddData = smart_ad_x ? { 'data-smart_ad_x': smart_ad_x } : null;
                        return (
                          <PItem
                            item={item}
                            key={index}
                            liAddData={liAddData}
                            liClass={'p-item'}
                            useRateNReviewCnt={
                              GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? true : false
                            }
                            useRemainCnt={false}
                            useRecommendCnt={false}
                            useShippingInfo={false}
                            useReferPrice={false}
                            useSoldCnt={false}
                            onclickFunc={smart_ad_x ? SmartADFunc : null}
                            linkType={'move'}
                          />
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          <div className="c-skeleton-title c-skeleton-title--s"></div>
          <div className="c-skeleton-slide">
            <div className="c-skeleton-card c-skeleton-card--grid3">
              <div className="c-skeleton-item">
                <div className="c-skeleton-item__image"></div>
                <div className="c-skeleton-item__info"></div>
              </div>

              <div className="c-skeleton-item">
                <div className="c-skeleton-item__image"></div>
                <div className="c-skeleton-item__info"></div>
              </div>

              <div className="c-skeleton-item">
                <div className="c-skeleton-item__image"></div>
                <div className="c-skeleton-item__info"></div>
              </div>
            </div>
          </div>
        </>
      )}

      <div ref={loaderRef} className="lst_load_more" id="div_lst_load_more">
        <a className="btn_more" id="lnk_loading_area">
          {MultiLang.findCommonResource('Main/us/index.aspx', 'Loading')}
          <span className="load_spinner3"></span>
        </a>
      </div>
    </div>
  );
};
export default ShoppingTrendKeyword;
