import Premium from './Premium';
import Chance from './Chance';
import { useEffect } from 'react';
const Tweet = ({ item }) => {
  useEffect(() => {
    // Following 셀러샵
    UICommonFollowingShop.init();
  }, []);

  return item.TWEET_TYPE === 'R' ? <Chance item={item} /> : <Premium item={item} />;
};
export default Tweet;
