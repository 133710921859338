import { useEffect } from 'react';
const Type2_3 = ({
  LIST,
  wepickData,
  wid,
  isPagingDisplay,
  PBLogFuncBase,
  PItem,
  bestSellerSticker,
  //loadingImg,
  pb_gdsc_cd,
  pb_keyword,
  pb_source,
  CHANNEL_ID,
}) => {
  const { DISP_TYPE, CONTENTS_TYPE } = wepickData;
  useEffect(() => {
    const pageCnt = Math.ceil(LIST.length / (DISP_TYPE * 2));
    if (pageCnt > 1) {
      const scrollFunc = (targetUl) => {
        const observeTop = $(window).scrollTop() + $(window).height();
        const target = $(`#io_${wid}`);
        const targetTop = target.offset().top + target.height() / 4;
        const targetLi = target.find('li[data-wish_org_gd_no]');
        if (targetLi.length > 0 && observeTop > targetTop) {
          const gd_no_list = [];
          $(targetUl)
            .find('li')
            .map(function () {
              const wish_org_gd_no = $(this).attr('data-wish_org_gd_no');
              wish_org_gd_no && gd_no_list.push(wish_org_gd_no);
              target.find(`li[data-wish_org_gd_no=${wish_org_gd_no}]`).removeAttr('data-wish_org_gd_no');
            });

          if (gd_no_list.length > 0) {
            Util.CallWishProductBoostLogApi('I', CHANNEL_ID, pb_gdsc_cd, pb_keyword, gd_no_list.toString(), pb_source);
          }
        }
      };
      const slideFunc = (swiper) => {
        scrollFunc(swiper.slides[swiper.activeIndex]);
      };

      const on =
        CONTENTS_TYPE === 'PB'
          ? {
              slideChange: slideFunc,
            }
          : null;

      const options = {
        slidesPerView: 'auto',
        loop: true,
        pagination: {
          el: `#dv_wepick_paging_${wid}`,
        },
        on,
      };
      new Swiper6(`#dv_wepick_slide_${wid}`, options);

      window.addEventListener('scroll', function () {
        scrollFunc($(`#dv_wepick_slide_${wid}`).find('ul')[0]);
      });
      scrollFunc($(`#dv_wepick_slide_${wid}`).find('ul')[0]);
    } else if (CONTENTS_TYPE === 'PB') {
      const scrollFunc = () => {
        const observeTop = $(window).scrollTop() + $(window).height();
        const target = $(`#io_${wid}`);
        const targetTop = target.offset().top + target.height() / 4;
        const targetLi = target.find('li[data-wish_org_gd_no]');
        if (targetLi.length > 0 && observeTop > targetTop) {
          const gd_no_list = [];
          targetLi.each(function () {
            const wish_org_gd_no = $(this).attr('data-wish_org_gd_no');
            gd_no_list.push(wish_org_gd_no);
            target.find(`li[data-wish_org_gd_no=${wish_org_gd_no}]`).removeAttr('data-wish_org_gd_no');
          });

          if (gd_no_list.length > 0) {
            Util.CallWishProductBoostLogApi('I', CHANNEL_ID, pb_gdsc_cd, pb_keyword, gd_no_list.toString(), pb_source);
          }
        }
      };
      window.addEventListener('scroll', function () {
        scrollFunc();
      });
      scrollFunc();
    }
  }, []);
  function splitIntoChunk(arr, chunk) {
    // 빈 배열 생성
    const result = [];

    for (let index = 0; index < arr.length; index += chunk) {
      let tempArray;
      // slice() 메서드를 사용하여 특정 길이만큼 배열을 분리함
      tempArray = arr.slice(index, index + chunk);
      // 빈 배열에 특정 길이만큼 분리된 배열을 추가
      result.push(tempArray);
    }

    return result;
  }
  const newList = splitIntoChunk(LIST, DISP_TYPE * 2);

  return (
    <div
      className={`p-itemcard p-itemcard--grid${DISP_TYPE} swp_tmp`}
      id={`dv_wepick_slide_${wid}`}
      data-slide_loop="true"
    >
      <div className="swp_cont">
        {newList.map((ulitem, ulindex) => {
          return (
            <ul className="p-itemcard__gallery swp_slide" key={`${wid}_${ulindex}`}>
              {ulitem.map((item, index) => {
                const plusCount = ulindex > 0 ? parseInt(DISP_TYPE) * 2 * ulindex : 0;
                const PBLogFunc = (event) => {
                  if (item.wish_org_gd_no) PBLogFuncBase(event, item.wish_org_gd_no, item.LINK_URL);
                };

                const useRecommendNSoldCnt =
                  GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? false : DISP_TYPE === '2' ? true : false;
                const priceNoticeStr =
                  CONTENTS_TYPE === 'TD' &&
                  GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.WeMakePriceWorld &&
                  DISP_TYPE === '2' ? (
                    <em className="sub sub--event" key={`${wid}_${index}`}>
                      {MultiLang.findCommonResource('Main/us/index.aspx', '타임세일가') || '타임세일가'}
                    </em>
                  ) : null;
                return (
                  <PItem
                    item={item}
                    key={`${wid}_${index}`}
                    liClass={'p-item'}
                    liAddData={{ 'data-wish_org_gd_no': item.wish_org_gd_no }}
                    {...bestSellerSticker(plusCount, index, CONTENTS_TYPE)}
                    onclickFunc={CONTENTS_TYPE === 'PB' ? PBLogFunc : null}
                    useRateNReviewCnt={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? true : false}
                    useRemainCnt={false}
                    useRecommendCnt={useRecommendNSoldCnt}
                    useShippingInfo={
                      GMKT.ServiceInfo.nation !== Qoo10FE.Constants.nation.Qoo10 && DISP_TYPE === '2' ? true : false
                    }
                    useReferPrice={false}
                    useSoldCnt={useRecommendNSoldCnt}
                    useGraph={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 && CONTENTS_TYPE === 'GB'}
                    useShipFromInfo={false}
                    priceNoticeStr={priceNoticeStr}
                    linkType={'move'}
                  />
                );
              })}
            </ul>
          );
        })}
      </div>
      {isPagingDisplay && <div className="swp_pg" id={`dv_wepick_paging_${wid}`}></div>}
    </div>
  );
};

export default Type2_3;
