import { useEffect } from 'react';
import { CONTENTS_OBJECT } from '@/common/model/constants';

const loadingImg = Public.GetLoadingImage('svg');

const SWIPER_ROOT_ID = 'div_main_key_corners_swiper';

const LIST = PageVariable[CONTENTS_OBJECT.KeyCornerList.DATA_NAME] || [];
const greaterThan10 = LIST.length > 10;

const KeyCornerList = () => {
  useEffect(() => {
    // Main Key Corners 영역
    if (!greaterThan10) return;

    new Swiper6(`#${SWIPER_ROOT_ID}`, {
      slidesPerView: 5.5,
      slidesPerColumn: 2,
      slidesPerColumnFill: 'column',
      slidesOffsetBefore: 8,
      slidesOffsetAfter: 8,
      scrollbar: {
        el: '.swiper-pagination',
        type: 'progressbar',
      },
    });
  }, []);

  if (LIST.length === 0) {
    return null;
  }

  const lessThen5Class = LIST.length <= 5 ? 'main-key__corner--less' : '';

  return (
    <div className="main-key" id={SWIPER_ROOT_ID}>
      <ul className={`main-key__corner swp_cont ${lessThen5Class}`}>
        {LIST.map((item, index) => {
          const etcInfoObj = Qoo10FE.Util.Object.jsonParse(item.ETC_INFO);
          const badge = etcInfoObj && etcInfoObj['badge'] ? etcInfoObj['badge'] : '';
          const link_type = etcInfoObj && etcInfoObj['link_type'] ? etcInfoObj['link_type'] : 'M';
          const targetBlankProps = link_type === 'N' ? { target: '_blank' } : null;
          return (
            <li className="swp_slide" key={index} data-priority={index}>
              <a href={item.LINK_URL} {...targetBlankProps}>
                <img className="key-img" gd_src={item.IMG_URL} src={loadingImg} load="N" alt="" />
                {item.TITLE}
              </a>
              {badge ? <i className={`badge-${badge}`}>time</i> : null}
            </li>
          );
        })}
      </ul>

      {greaterThan10 && (
        <>
          <div className="swiper-pagination"></div>
          <span className="swp_notification" aria-live="assertive" aria-atomic="true"></span>
        </>
      )}
    </div>
  );
};
export default KeyCornerList;
