import Type1 from './DispType/Type1';
import Type2_3 from './DispType/Type2_3';
import TypeS from './DispType/TypeS';

const loadingImg = Public.GetLoadingImage('svg');

/* 
    WID : key 값
    PRIORITY : 우선순위
    TITLE : 타이틀
    LINK_URL : 연결 링크
    DISP_TYPE	
        1: 1 items
        2: 2 items
        3: 3 items 
        S: Slide view
    CONTENTS_TYPE	BS: 베스트셀러
        PD: 데일리딜
        TD: 타임세일
        GB: 그룹바이
        TH: 테마
        GID: gid
        SS; 스마트서치
        OSS: oss mall
        QBO: qoobo 추천
*/
const ItemCardArea = ({ LIST, wepickData, wid }) => {
  const { DISP_TYPE, CONTENTS_TYPE } = wepickData;

  const isPagingDisplay = (DISP_TYPE === '2' && LIST.length > 4) || (DISP_TYPE === '3' && LIST.length > 6);

  const contentsInfoObj = CONTENTS_TYPE === 'PB' && Qoo10FE.Util.Object.jsonParse(wepickData.CONTENTS_INFO);
  const pb_gdsc_cd = contentsInfoObj && contentsInfoObj['pb_gdsc_cd'];
  const pb_keyword = contentsInfoObj && contentsInfoObj['pb_keyword'];
  const pb_source = contentsInfoObj && contentsInfoObj['pb_source'];
  const CHANNEL_ID = MobileUtil.isApp() ? 'APP_WEPICK_MAIN_CH' : 'MOBILE_WEPICK_MAIN_CH';

  //백엔드 로컬 테스트시 너무 느려서 log 처리
  if (location.search.indexOf('test=Y') > 0) {
    Util.CallWishProductBoostLogApi = (a, b, c, d, e, f) => {
      console.log(a, b, c, d, e, f);
    };
  }

  //PB상품일때 데이터 셋팅
  if (CONTENTS_TYPE === 'PB') {
    LIST.map((item) => {
      const etcInfo = item.ETC_INFO ? Qoo10FE.Util.Object.jsonParse(item.ETC_INFO) : null;
      const wish_org_gd_no = etcInfo && etcInfo['wish_org_gd_no'] ? etcInfo['wish_org_gd_no'] : null;
      if (wish_org_gd_no) item['wish_org_gd_no'] = wish_org_gd_no;
    });
  }

  const PBLogFuncBase = (event, gd_no, LINK_URL) => {
    window.open(LINK_URL);
    if (gd_no !== '') {
      Util.CallWishProductBoostLogApi('C', CHANNEL_ID, pb_gdsc_cd, pb_keyword, gd_no, pb_source);
    }
    //location.href = LINK_URL;
    event.preventDefault();
    return true;
  };

  const { PItem } = Qoo10FEComponents;

  const bestSellerSticker = (plusCount, index, type) => {
    return type === 'BS'
      ? {
          useSticker: true,
          stickerText: (plusCount + (index + 1)).toString().padStart(2, '0'),
          stickerClass: plusCount + index > 2 ? 'sticker__basic' : '',
        }
      : null;
  };

  const commonProps = {
    LIST,
    wepickData,
    wid,
    isPagingDisplay,
    PBLogFuncBase,
    PItem,
    bestSellerSticker,
    loadingImg,
    pb_gdsc_cd,
    pb_keyword,
    pb_source,
    CHANNEL_ID,
  };

  return DISP_TYPE === '1' ? (
    <Type1 {...commonProps} />
  ) : DISP_TYPE === '2' || DISP_TYPE === '3' ? (
    <Type2_3 {...commonProps} />
  ) : DISP_TYPE === 'S' ? (
    <TypeS {...commonProps} />
  ) : null;
};
export default ItemCardArea;
