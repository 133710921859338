import Push from './Push';
import Tweet from './Tweet';
import { adButtonOnClickFunc } from '../../../../common/util/function';

const PushTweet = ({ data }) => {
  if (data?.length <= 0) return;

  return data.map((item, index) => {
    return (
      <li data-type={item.TYPE} lang={item.LANG_CD} key={index} onClick={adButtonOnClickFunc}>
        {item.TYPE === 'push' ? <Push item={item} /> : <Tweet item={item} />}
      </li>
    );
  });
};
export default PushTweet;
