import { CONTENTS_OBJECT } from '@/common/model/constants';
import { createRoot } from 'react-dom/client';
import { useEffect } from 'react';
import Contents from './Contents';

/* 
    WID : key 값
    PRIORITY : 우선순위
    TITLE : 타이틀
    LINK_URL : 연결 링크
    DISP_TYPE	
        1: 1 items
        2: 2 items
        3: 3 items 
        S: Slide view
    CONTENTS_TYPE	BS: 베스트셀러
        PD: 데일리딜
        TD: 타임세일
        GB: 그룹바이
        TH: 테마
        GID: gid
        SS; 스마트서치
        OSS: oss mall
        QBO: qoobo 추천
*/
const wePickObj = CONTENTS_OBJECT.WepickList;
const wepickList = PageVariable[wePickObj.DATA_NAME];
const wepickData = wepickList?.reduce(
  (acc, item) => ({
    ...acc,
    [item.WID]: item,
  }),
  {},
);
const wePickItemsObj = CONTENTS_OBJECT.WepickItems;
const wepickItems = PageVariable[wePickItemsObj.DATA_NAME];

const WePick = () => {
  useEffect(() => {
    function renderItems(target) {
      const wid = $(target).attr('data-wid');

      const getNormalWidReq = () => {
        // //캐쉬된 데이터 호출
        // RMSHelper.asyncCallWebObject(
        //   Public.getServiceUrl(`Mobile/Main/FEWepickAPI.aspx?wid_list=${wid}`),
        //   'post',
        //   null,
        //   function (result, svc, xmlHttp) {
        //     try {
        //       render(JSON.parse(result));
        //     } catch {
        //       /* empty */
        //     }
        //   },
        // );
        const param = new RMSParam();
        param.add('wid_list', wid);
        param.add('display_channel', 'MW');
        RMSHelper.asyncCallWebMethod(
          Public.getServiceUrl('swe_MainAjaxService.asmx'),
          'GetWepickItems',
          param.toJson(),
          function (result, svc, methodName, xmlHttpasync) {
            render(result);
          },
        );
      };

      // qbo 개인화 데이터 조회
      const getQooboWidReq = () => {
        const param = new RMSParam();
        param.add('wid_list', wid);
        param.add('display_channel', 'MW');
        RMSHelper.asyncCallWebMethod(
          Public.getServiceUrl('Mobile/Main/FEMainAPI.aspx'),
          'GetWepickItems',
          param.toJson(),
          function (result, svc, methodName, xmlHttpasync) {
            render(result);
          },
        );
      };
      function render(list) {
        target.style.height = '';
        $(target).attr('class', $(target).attr('data-class'));
        createRoot(target).render(<Contents wePickItems={list[wid]} wepickData={wepickData[wid]} wid={wid} />);
      }

      if (wepickItems[wid]) {
        render(wepickItems);
      } else {
        if (wepickData[wid].CONTENTS_TYPE === 'QBO') {
          getQooboWidReq();
        } else {
          getNormalWidReq();
        }
      }
    }

    // 화면에 보이지 않는 dom observer로 셋팅
    const io = new IntersectionObserver(
      (entries, observer) => {
        const filtered = entries.filter((entry) => entry.isIntersecting);
        filtered.forEach((entry) => {
          observer.unobserve(entry.target);
          renderItems(entry.target);
        });
      },
      {
        rootMargin: '200px 0px ',
      },
    );

    const mo = new MutationObserver(() => {
      StickerLabel.init(); //Sticker 타이머 온
    });
    const moConfig = {
      childList: true, // 자식노드 추가/제거 감지
    };

    function initObserver() {
      [...document.querySelectorAll('[data-type=io_div]')].forEach((div) => {
        io.observe(div);
        mo.observe(div, moConfig);
      });
    }

    initObserver();
  }, []);

  return wepickList?.length > 0
    ? wepickList
        .sort((a, b) => a - b)
        .map((item, index) => {
          const gdArr = [0, 1];
          if (item.DISP_TYPE === '3') {
            gdArr.push(2);
          }
          return (
            <div
              className={'main-row'}
              data-class={
                item.DISP_TYPE === 'S' && wepickData[item.WID].BACKGROUND_IMG_URL
                  ? 'main-cardsetting'
                  : item.DISP_TYPE === 'S'
                    ? 'main-cardsetting main-cardsetting--nobg'
                    : 'main-row'
              }
              id={`io_${item.WID}`}
              key={index}
              data-type={'io_div'}
              data-wid={item.WID}
              data-priority={item.PRIORITY}
            >
              <div className="c-skeleton-title"></div>
              {item.DISP_TYPE === '1' ? (
                <div className="c-skeleton-card">
                  <div className="c-skeleton-item">
                    <div className="c-skeleton-item__image"></div>
                    <div className="c-skeleton-item__info"></div>
                  </div>
                </div>
              ) : item.DISP_TYPE === '2' || item.DISP_TYPE === '3' ? (
                <div className={`c-skeleton-card c-skeleton-card--grid${item.DISP_TYPE}`}>
                  {gdArr.map((i, index) => {
                    return (
                      <div className="c-skeleton-item" key={i + index}>
                        <div className="c-skeleton-item__image"></div>
                        <div className="c-skeleton-item__info"></div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="c-skeleton-slide">
                  <div className="c-skeleton-card c-skeleton-card--grid3">
                    <div className="c-skeleton-item">
                      <div className="c-skeleton-item__image"></div>
                      <div className="c-skeleton-item__info"></div>
                    </div>

                    <div className="c-skeleton-item">
                      <div className="c-skeleton-item__image"></div>
                      <div className="c-skeleton-item__info"></div>
                    </div>

                    <div className="c-skeleton-item">
                      <div className="c-skeleton-item__image"></div>
                      <div className="c-skeleton-item__info"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })
    : null;
};

export default WePick;
