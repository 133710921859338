import { useEffect } from 'react';

const loadingImg = Public.GetLoadingImage('svg');

const Chance = ({ item }) => {
  useEffect(() => {
    // Following 셀러샵
    UICommonFollowingShop.init();
  }, []);

  const isBig = item.VC_NO > 0 && item.VIDEO_THUMB_IMG;
  const title = !item.COMMENT ? item.TITLE : `${item.TITLE} / ${item.COMMENT}`;
  const etcInfoObj = Qoo10FE.Util.Object.jsonParse(item.ETC_INFO);
  const send_dt = etcInfoObj && etcInfoObj['send_dt'] ? etcInfoObj['send_dt'] : item.ETC_INFO;

  return (
    <a href={item.LINK_URL} className="tweet-feed">
      <div
        className={
          isBig ? 'tweet-feed__thumb tweet-feed__thumb--size-big' : 'tweet-feed__thumb tweet-feed__thumb--type-square'
        }
        gd_src={item.IMG_URL}
        load="N"
        style={{ backgroundImage: `url(${loadingImg})` }}
      >
        {item.VC_NO > 0 ? <span className="play">Play</span> : ''}
      </div>
      <div className="tweet-feed__cont">
        {!is_big ? <div className="tweet-feed__comment">{item.COMMENT}</div> : ''}
        <div className="tweet-feed__desc">{title}</div>
        <div className="tweet-feed__detail">
          <div className="sender">
            <span
              className="sender__img"
              gd_src={item.SHOP_IMAGE}
              load="N"
              style={{ backgroundImage: `url(${loadingImg})` }}
            ></span>
            <span className="sender__info">
              {isBig ? (
                <>
                  <span className="title">
                    <span className="name">{item.SHOP_TITLE}</span>
                    <span
                      className="tag-fllwg"
                      title="following"
                      style={{ display: 'none' }}
                      data-sell_cust_no={item.ENC_SELL_CUST_NO}
                      data-chk_follow="N"
                    >
                      <span className="hide">Following</span>
                    </span>
                  </span>
                  <span className="time">{send_dt}</span>
                </>
              ) : (
                <span className="price">{item.DISPLAY_FINAL_PRICE}</span>
              )}
            </span>
          </div>
        </div>
      </div>
    </a>
  );
};
export default Chance;
