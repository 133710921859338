export const adButtonOnClickFunc = (event, style) => {
  if (
    $(event.target).data('id') === 'ad_button' &&
    $(event.target).parent('.c-tooltip').find('[data-id=ad_layer]').length <= 0
  ) {
    $('[data-id=ad_layer]').remove();
    $(event.target).parent('.c-tooltip')[0].innerHTML +=
      `<div class="c-tooltip__cont" data-id="ad_layer" ${style || null}><button type="button" class="c-tooltip__close" onclick="$(this).parent('div').remove();"><span class="hide">닫기</span></button><div>${MultiLang.findCommonResource('Main/us/index.aspx', 'The ad space purchased by the seller') || 'The ad space purchased by the seller.'}</div></div>`;
  } else {
    $(event.target).parent('.c-tooltip').find('[data-id=ad_layer]').remove();
  }
};
