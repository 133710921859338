import LiveCast from './LiveCast';
import PushTweet from './PushTweet';
import BrandPremium from './BrandPremium';
import ADLiveBanner from './ADLiveBanner';
import { useState, useEffect } from 'react';

const ShoppingTweetContents = () => {
  const ID_LIVECAST = 'io_tweet_livecast';
  const ID_PUSHTWEET = 'io_tweet_pushtweet';
  const ID_BRANDPREMIUM = 'io_tweet_brandpremium';
  const ID_ADLIVEBANER = 'io_tweet_adlivebanner';
  const API_URL = Public.getServiceUrl('Mobile/Main/FEMainAPI.aspx');
  const idList = [ID_LIVECAST, ID_PUSHTWEET, ID_BRANDPREMIUM, ID_ADLIVEBANER];

  const [liveCastLi, setLiveCastLi] = useState(<li id={ID_LIVECAST} style={{ height: '150px' }} data-load="N"></li>);
  const [pushTweetLi, setPushTweetLi] = useState(<li id={ID_PUSHTWEET} style={{ height: '150px' }} data-load="N"></li>);
  const [brandPremiumLi, setBrandPremiumLi] = useState(
    <li id={ID_BRANDPREMIUM} style={{ height: '150px' }} data-load="N"></li>,
  );
  const [adLiveBannerLi, setAdLiveBannerLi] = useState(
    <li id={ID_ADLIVEBANER} style={{ height: '150px' }} data-load="N"></li>,
  );

  useEffect(() => {
    // 화면에 보이지 않는 dom observer로 셋팅
    const Observe = {
      // 매칭되면 randing해주는 함수
      rand: function (targetId) {
        const element = $(`#${targetId}`);

        if (element && element.attr('data-load') === 'N') {
          const param = new RMSParam();
          if (targetId === ID_LIVECAST) {
            RMSHelper.asyncCallWebMethod(API_URL, 'GetMobileLiveCastItems', param.toJson(), function (result) {
              setLiveCastLi(<LiveCast data={result} />);
            });
          } else if (targetId === ID_PUSHTWEET) {
            RMSHelper.asyncCallWebMethod(API_URL, 'GetMobilePushTweetItems', param.toJson(), function (result) {
              setPushTweetLi(<PushTweet data={result} />);
            });
          } else if (targetId === ID_BRANDPREMIUM) {
            RMSHelper.asyncCallWebMethod(API_URL, 'GetBrandPremiumItems', param.toJson(), function (result) {
              setBrandPremiumLi(<BrandPremium data={result} />);
            });
          } else if (targetId === ID_ADLIVEBANER) {
            RMSHelper.asyncCallWebMethod(API_URL, 'GetADLiveBannerItems', param.toJson(), function (result) {
              setAdLiveBannerLi(<ADLiveBanner data={result} />);
            });
          }
        }
      },
      io: new IntersectionObserver(
        (entries) => {
          const filtered = entries.filter((entry) => entry.isIntersecting);
          filtered.forEach((entry) => {
            Observe.io.unobserve(entry.target);
            Observe.rand(entry.target.id);
          });
        },
        {
          rootMargin: '600px 0px ',
        },
      ),

      init: function () {
        const mo = new MutationObserver(() => {
          //Sticker 타이머 온
          StickerLabel.init();
        });
        const moConfig = {
          childList: true, // 자식노드 추가/제거 감지
        };

        idList.map((id) => {
          Observe.io.observe($(`#${id}`)[0]);
          mo.observe($(`#${id}`)[0], moConfig);
        });
      },
    };
    Observe.init();
  }, []);

  // useEffect(() => {
  //   let existsData = false;
  //   idList.map((id) => {
  //     if ($(`#tweet_ul [data-load!=N]`).length > 0) {
  //       existsData = true;
  //     }
  //   });
  //   if (!existsData) $('#tweet_area').remove();
  // }, [liveCastLi, pushTweetLi, brandPremiumLi, adLiveBannerLi, idList]);

  return (
    <>
      <div className="main-title main-title--link">
        <h2 className="main-title__name">
          {MultiLang.findCommonResource('Main/us/index.aspx', 'Shopping Tweet Title')}
        </h2>
        <a className="main-title__link" href={Public.getServiceUrl('Mobile/ShoppingTweet/TimeLine.aspx?shoplog=Y')}>
          <span className="hide">전체 보기</span>
        </a>
      </div>
      <div className="main-tweet">
        <ul>
          {liveCastLi}
          {pushTweetLi}
          {brandPremiumLi}
          {adLiveBannerLi}
        </ul>
      </div>
    </>
  );
};
export default ShoppingTweetContents;
