export const AdToolTip = ({ rootClass }) => {
  return (
    <div className={rootClass || 'all-promotion__ad'}>
      <div className="c-tooltip">
        <button type="button" className="c-label c-label--ad" data-id="ad_button">
          {MultiLang.findCommonResource('Master/DefaultMasterPage.master', 'AD') || 'AD'}
        </button>
      </div>
    </div>
  );
};
