import { useEffect } from 'react';
const TypeS = ({
  LIST,
  wepickData,
  wid,
  //isPagingDisplay,
  PBLogFuncBase,
  PItem,
  bestSellerSticker,
  loadingImg,
  pb_gdsc_cd,
  pb_keyword,
  pb_source,
  CHANNEL_ID,
}) => {
  const { CONTENTS_TYPE } = wepickData;
  useEffect(() => {
    //PB상품타입일때만 init, sliceChange 이벤트 추가
    const scrollFunc = (idx) => {
      const observeTop = $(window).scrollTop() + $(window).height();
      const target = $(`#io_${wid}`);
      const targetTop = target.offset().top + target.height() / 4;
      const targetLi = target.find('li[data-wish_org_gd_no]');
      if (targetLi.length > 0 && observeTop > targetTop) {
        const itemLiWidth = 144; // li width + margin right
        const dispItemCount = Math.ceil($(`#dv_wepick_slide_${wid}`).width() / itemLiWidth);
        const targetLi = $(`#dv_wepick_slide_${wid}`).find('ul li');
        const gd_no_list = [];
        const startIdx = idx;
        const endIdx = startIdx + dispItemCount > LIST.length ? LIST.length : startIdx + dispItemCount;
        for (let i = startIdx; i < endIdx; i++) {
          const wish_org_gd_no = $(targetLi[i]).attr('data-wish_org_gd_no');
          if (wish_org_gd_no) {
            gd_no_list.push(wish_org_gd_no);
            $(targetLi[i]).removeAttr('data-wish_org_gd_no');
          }
        }
        if (gd_no_list.length > 0) {
          Util.CallWishProductBoostLogApi('I', CHANNEL_ID, pb_gdsc_cd, pb_keyword, gd_no_list.toString(), pb_source);
        }
      }
    };

    const slideFunc = (swiper) => {
      scrollFunc(swiper.activeIndex);
    };
    const on =
      CONTENTS_TYPE === 'PB'
        ? {
            slideChange: slideFunc,
          }
        : null;

    const options = {
      slidesPerView: 'auto',
      on,
    };
    new Swiper6('#dv_wepick_slide_' + wid, options);

    window.addEventListener('scroll', function () {
      scrollFunc(0);
    });
    scrollFunc(0);
  }, []);

  return (
    <div
      className="main-cardsetting__cont swp_tmp"
      style={{ backgroundColor: `#${wepickData.BACKGROUND_COLOR}` }}
      id={`dv_wepick_slide_${wid}`}
      data-slide_loop="true"
    >
      <ul className="swp_cont">
        {LIST.map((item, index) => {
          let manual_image_path = '';
          if (item.ETC_INFO != '') {
            const etcInfoObj = Qoo10FE.Util.Object.jsonParse(item.ETC_INFO);
            manual_image_path = etcInfoObj && etcInfoObj['manual_image_path'] ? etcInfoObj['manual_image_path'] : '';
          }
          const PBLogFunc = (event) => {
            if (item.wish_org_gd_no) PBLogFuncBase(event, item.wish_org_gd_no, item.LINK_URL);
          };

          return manual_image_path ? (
            <li className="main-cardsetting__banner swp_slide" key={`${wid}_${index}`}>
              <a href={item.LINK_URL}>
                <img src={loadingImg} data-src={manual_image_path} load="N" alt={item.GD_NM} />
              </a>
            </li>
          ) : (
            <PItem
              item={item}
              key={`${wid}_${index}`}
              liClass={'p-item swp_slide'}
              liAddData={{ 'data-wish_org_gd_no': item.wish_org_gd_no }}
              {...bestSellerSticker(0, index, CONTENTS_TYPE)}
              onclickFunc={CONTENTS_TYPE === 'PB' ? PBLogFunc : null}
              useRateNReviewCnt={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? true : false}
              useRemainCnt={false}
              useRecommendCnt={false}
              useShippingInfo={false}
              useReferPrice={false}
              useSoldCnt={false}
              useGraph={CONTENTS_TYPE === 'GB'}
              linkType={'move'}
            />
          );
        })}
      </ul>
      <span className="swp_notification" aria-live="assertive" aria-atomic="true"></span>
    </div>
  );
};

export default TypeS;
