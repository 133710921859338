import { useEffect } from 'react';

const loadingImg = Public.GetLoadingImage('svg');
const loadingShopImg = Public.GetLoadingImage('shop');

const LiveCast = ({ data }) => {
  useEffect(() => {
    // Following 셀러샵
    UICommonFollowingShop.init();
  }, []);

  if (data?.length <= 0) return;

  return data.map((item, index) => {
    const etcInfoObj = Qoo10FE.Util.Object.jsonParse(item.ETC_INFO);
    const send_dt = etcInfoObj && etcInfoObj['send_dt'] ? etcInfoObj['send_dt'] : item.ETC_INFO;
    return (
      <li data-type="live" key={index}>
        <a href={item.LINK_URL} className="tweet-feed">
          <div
            className="tweet-feed__thumb tweet-feed__thumb--size-big"
            gd_src={item.IMG_URL}
            load="N"
            style={{ backgroundImage: `url(${loadingImg})` }}
          >
            <span className="play">Play</span>
            {/* 현재 라이브 방송 중이면 */}
            {item.ONAIR && <span className="flag">Live</span>}
          </div>
          <div className="tweet-feed__cont">
            <div className="tweet-feed__desc">{item.TITLE}</div>
            <div className="tweet-feed__detail">
              <div className="sender">
                {item.SHOP_IMAGE && (
                  <span
                    className="sender__img"
                    gd_src={item.SHOP_IMAGE}
                    load="N"
                    style={{ backgroundImage: `url(${loadingShopImg})` }}
                  ></span>
                )}
                <span className="sender__info">
                  <span className="name">{item.SHOP_TITLE}</span>
                  <span
                    className="tag-fllwg"
                    title="following"
                    style={{ display: 'none' }}
                    data-sell_cust_no={item.ENC_SELL_CUST_NO}
                    data-chk_follow="N"
                  >
                    <span className="hide">Following</span>
                  </span>
                  {send_dt && <span className="time">{send_dt}</span>}
                </span>
              </div>
            </div>
          </div>
        </a>
      </li>
    );
  });
};
export default LiveCast;
