import { createRoot } from 'react-dom/client';
import MainBannerList from '@/components/MainBannerList';
import SlideBannerList from '@/components/SlideBannerList';
import KeyCornerList from '@/components/KeyCornerList';
import ShoppingTweet from '@/components/ShoppingTweet';
import WePick from '@/components/WePick';
import ShoppingTrendKeyword from '../../components/ShoppingTrendKeyword';

const container = document.getElementById('div_fe_main');
const root = createRoot(container);
root.render(
  <>
    <MainBannerList />
    <KeyCornerList />
    <SlideBannerList />
    <WePick />
    <ShoppingTweet />
    <ShoppingTrendKeyword />
  </>,
);
